<script setup lang="ts">
import {
    IconsSword2GradientPrimary,
    IconsMode,
    IconsFire
} from "#components";

const props = defineProps<{
    battle: CaseBattleInterface;
    hideIcons?: boolean;
}>();

const modes = computed(() => {
    const m = [
        {
            name: "Terminal",
            variable: "terminal_mode",
            color: "#FF7474",
            icon: IconsFire
        },
        {
            name: "Cursed",
            variable: "cursed_mode",
            color: "#87D98E",
            icon: IconsSword2GradientPrimary
        }
    ].filter(v => (props.battle as any)[v.variable]);

    if (m.length > 0) {
        return m;
    }

    return [
        {
            name: "Normal",
            variable: "",
            color: "#507BEB",
            icon: IconsMode
        }
    ];
});
</script>

<template>
    <div class="flex items-center gap-2 overflow-hidden">
        <template v-for="(v, k) in modes" :key="k">
            <div class="flex items-center gap-2">
                <component
                    :is="v.icon"
                    v-if="k == 0 && !hideIcons"
                    class="h-5 w-5"
                    :color="v.color"
                    :steps="[]"
                    :style="{
                        color: v.color
                    }"
                />
                <span
                    :style="{
                        color: v.color
                    }"
                >
                    {{ v.name }}
                </span>
            </div>

            <span
                v-if="k != modes.length - 1"
                class="text-textGray-1"
            >
                +
            </span>
        </template>
    </div>
</template>
